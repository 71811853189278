@use "../abstracts" as *;

.sent-cards {
  position: relative;
}

#account-update .layout__item {
  @include xs {
    grid-column: 1/1;
  }
}

.myaccount-cards {
  margin-bottom: 1rem;
}

.myaccount-card {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  .myaccount-image {
    flex: 25%;
    max-width: 25%;
    &__title {
      text-align: center;
      margin: 0.5rem 0;
      p {
        font-size: .9125rem;
        font-weight: 500;
      }
    }
    &__controls {
      display: flex;
      justify-content: center;
      font-size: .9375rem;
    }

    &__resend {
      text-align: end;
    }

    &__cancel {
      button {
        text-decoration: underline;
      }
    }
  }

  .myaccount-info {
    display: flex;
    flex-direction: column;
    flex: 70%;
    max-width: 70%;
    :first-child {
      margin-top: 0;
    }
    &__head {
      margin: 0.3rem 0 .7rem;
      p {
        font-size: .875rem;
        font-weight: 500;
      }
    }
    &__body {
      font-size: .9375rem;
      overflow-wrap: anywhere;
      p {
        margin-bottom: 0.35rem;
      }
    }
  }

  @include xs {
    flex-direction: column;
    padding: 1rem;
    border: 1px solid var(--ui-divider-color);
    border-radius: var(--ui-roundness);
    margin-top: 0;
    .myaccount-info {
      flex: 100%;
      width: 100%;
      max-width: 100%;
      margin-top: 1rem;
      padding-bottom: 2rem;
    }
    .myaccount-image {
      align-self: center;
      max-width: 100%;
    }
  }
}

.empty-response-wrapper {
  &.--hidden {
    display: none;
  }
}

.order-histroy-page {
  .auto-renew-text {
    margin-top: 2rem;
  }

  .table-item__image {
    img {
      margin: 0 auto 15px;
      width: 90%;
    }
  }

  @include xs {
    .tabs__tab {
      font-size: 0.8rem;
      flex: 1;
    }
  }
}

#email-preferences,
#manage-membership {
  --ui-vertical-spacing: 1.1rem;
}

.reset-filters,
.filter-email {
  &.--hidden {
    display: none;
  }
}

#pof-update {
  .accordion__content {
    padding: 1.2rem 0 0;
  }

  table {
    thead {
      position: relative;
      top: unset;
      th {
        padding: 0 .70rem;
        color: var(--color-gray-2);
        font-size: 0.7825rem;
        letter-spacing: .2px;
        @include xxs {
          font-size: .7rem;
          padding: 0 .5rem;
        }
      }
    }

    tbody {
      td {
        @include xxs {
          font-size: .7rem;
          padding: 0 .5rem;
        }
      }
    }
  }
}

.payment-info, .billing-info {
  h2 {
    margin-bottom: .5rem;
  }
  p:not(:last-child) {
    margin-bottom: .25rem
  }
}

.manage-membership-block {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1.25rem;
  border-radius: 10px;
  border: 1px solid  var(--color-gray-6);
  background: #f6f6f6;
  svg {
    min-width: 32px;
  }
}

#manage-membership {
  .field {
    --ui-checkbox-size: 40px;
    @include sm {
      justify-items: center;
    }
  }
}