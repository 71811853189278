@use "../../abstracts" as *;

.form {
  --fieldset-spacing: 2.2rem;
  --contain-width-small: 450px;
  position: relative;

  @include sm {
    --fieldset-spacing: 2rem;
  }

  &.--wait-init:not(.--init) {
    pointer-events: none;
  }

  &.--contain-width-column-1 {
    max-width: var(--contain-width-small);
  }

  &.--contain {
    max-width: 600px;
  }

  &.--dynamic-contain {
    max-width: 360px;

    @include xs {
      margin: 0 auto;
    }
  }

  &.--contain-small {
    max-width: 400px;

    &.--success {
      &.--full-width-success {
        max-width: 100%;
      }
    }

    &.--center {
      margin: auto;
    }
  }

  &.--contain-smaller {
    max-width: 375px;
  }

  &.--contain-smallest {
    max-width: 360px;
  }

  &.--contain,
  &.--contain-small,
  &.--contain-smaller,
  &.--contain-smallest {
    @include sm {
      .main-content-wrapper & {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .dialog & {
    width: 600px;
    max-width: 100%;

    &.--full {
      width: 100%;
    }

    &.addressbook {
      width: 730px;
      max-width: 100%;

      @include sm {
        width: 640px;
      }

      @include xs {
        width: 400px;
      }
    }
  }

  &.--small {
    width: 400px;
    max-width: 100%;
  }

  &.--full-width {
    width: 100%;
    max-width: 100%;
  }

  .btn.--submit {
    margin-top: var(--gap);
  }

  .inline__item & {
    width: auto;
  }

  .inline__item & .btn.--submit,
  .inline__item .btn.--submit,
  .inline__item .btn.--secondary,
  .btn.--submit.--full-width {
    margin-top: 0;
  }

  .data-rights__content & {
    margin-top: 0;
  }

  &.--wider-inter-spacing {
    --global-inter-content-spacing: 1rem;
  }

  .dialog[data-prompt] & {
    width: 400px;
    max-width: 100%;
  }

  .layout {
    --layout-spacing: var(--global-inter-content-spacing, .75rem);
    --layout-spacing-larger: var(--global-inter-content-spacing, 1rem);
  }

  &__event-fieldset {
    .field:not(:last-child) {
      margin-bottom: 0;
    }

    .layout__item>.field {
      display: flex;
      align-items: flex-start;
    }

    .--event-structure {
      --layout-spacing: 0px;
      flex-flow: row;
      align-items: start;
      padding-top: 1rem;

      @include xs {
        flex-flow: wrap;
      }

      &> :nth-child(1) {
        width: 60%;

        @include xs {
          width: 100%;
        }
      }

      &> :nth-child(2) {
        width: 42%;
        padding-left: calc(var(--gap) * 1.5);
        display: flex;
        flex-flow: wrap;
        gap: 8px 16px;
        border-left: 1px var(--ui-divider-color) solid;
        margin-top: 15px;

        @include xs {
          padding-left: 0;
          border-left: none;
          width: 100%;
          margin-top: 0px;
        }
      }
    }

    .--full-date-structure {
      flex-flow: wrap;

      @include max(370) {
        flex-flow: wrap !important;
      }

      &>* {
        flex: 1;

        &:nth-child(2) {
          flex: 0.4;
          min-width: 80px;

          @include max(370) {
            flex: 0.5;
          }
        }

        &:nth-child(3) {
          flex: 0.4;
          min-width: 72px;

          @include max(370) {
            flex: 0.5;
          }
        }

        @include max(370) {
          flex: auto;
        }
      }

      gap: calc(var(--gap) * 1.25);

      @include xs {
        gap: var(--gap);
      }
    }

    .checkbox {
      padding: 0;
    }
  }

  &__info {
    text-decoration: none;
    text-align: left;
    height: var(--info-bar-height, auto);
    overflow: hidden;
    @include trns;

    &-inner {
      padding: 8px;
      border-radius: var(--ui-roundness, 0px);
      background-color: rgba($color-form-info, 0.07);
      color: var(--color-info-text);
    }

    p {
      position: relative;
      font-size: 0.9375rem;
      letter-spacing: 0.4px;
      padding: 6px 10px 6px;
      padding-left: 35px;

      strong {
        font-weight: 500;
      }

      a {
        color: var(--color-info-link) !important;

        &:hover {
          color: var(--color-info-link-hover) !important;
        }
      }

      &:after {
        content: "";
        content: "" / " info icon";
        background: var(--icon-info-tip);
        width: 14px;
        height: 14px;
        position: absolute;
        top: 10px;
        left: 10px;
        opacity: 1;
      }
    }

    &:not(:last-child):not(.--no-spacing) {
      margin-bottom: var(--global-inter-content-spacing, 0.75rem);
      &.--fieldset-spacing {
        margin-bottom: var(--fieldset-spacing, 2.2rem);
      }
    }

    &.--empty {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0 !important;
      height: 0px;
    }
  }

  &__loading {
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.693);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.693);
    transition: opacity .2s var(--reveal-easing);
    pointer-events: none;
    z-index: 30;
    width: 100%;
    height: 100%;
    position: fixed;
    display: grid;
    place-content: center;
    box-shadow: none;
    left: 0;
    top: 0;
    color: var(--color-primary-lighter);

    .main-content-wrapper > & {
      z-index: 20;
      width: 100%;
      height: 100%;
      position: fixed;
      box-shadow: none;
      left: 0;
      top: 0;

      body.--scroll-freeze & {
        display: none;
      }

      @include to_sm {
        display: none;
      }
    }

    .css-icon {
      --size: 90px;
      --weight: 3px;
      --scale: .75;
      @include sm {
        --size: 60px;
        --weight: 2px;
      }

      .glow {
        &:before {
          display: none;
        }
      }
    }

    &-inner {
      min-width: 100px;
      min-height: 100px;
      display: grid;
      outline: none;
      place-content: center;
      border-radius: 50%;
    }

    &.--loading,
    .--loading & {
      opacity: 1;
      pointer-events: all;
      .css-icon{
        --scale: 1;
      }
      .form__loading-inner {
        transform: scale(1);
      }
    }

    .aside & {
      @include sm {
        width: 0;
        height: 0;
        position: sticky;
        width: 0px;
        top: calc(var(--aside-spacing) + var(--aside-spacing-top-offset, 0px));
        .form__loading-inner {
          border-radius: 0px;
          background-color: inherit;
          position: absolute;
          left: calc(var(--aside-spacing) * -1);
          top: calc(var(--aside-spacing) * -1 - var(--aside-spacing-top-offset, 0px));
          width: calc(var(--client-width, 100vw) * .9);
          max-width: 440px;
          height: var(--client-height, 100vh);
        }
      }
    }

  }

  &__errors {
    --error-container-spacing: 8px;
    --error-invalid-color: #{rgba($color-form-error, 0.07)};
    --error-valid-color: #{rgba($color-form-valid, 0.14)};
    height: var(--js-height, 0px);
    overflow: hidden;
    color: var(--color-form-error);
    text-align: left;
    border-radius: var(--ui-roundness, 0px);
    @include trns;

    &:not(:last-child) {
      margin-bottom: var(--global-inter-content-spacing, 0.75rem);
      .form.--smaller & {
        margin-bottom: .4rem;
      }
    }

    &:not(:first-child) {
      margin-top: var(--global-inter-content-spacing, 0.75rem);

      #sign-in & {
        margin-top: 0px;
      }
    }

    &-list {
      padding: var(--error-container-spacing);
      margin: 0;
    }

    &.--empty {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      height: 0px;
    }
  }

  &__field {
    display: grid;
    gap: 1rem;
  }

  &__options {
    display: flex;
    flex-flow: wrap;
    gap: var(--gap);

    &.--grow {
      &>* {
        flex: 1;
      }
    }

    &>* {
      grid-template-rows: max-content;
      margin-bottom: 0 !important;
      gap: 0 !important;
    }

    &.--music-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: max-content;
      padding: 0;
      @include sm {
        grid-template-columns: 1fr;
      }

      @include xxs {
        display: flex;
        overflow-x: scroll;
        flex-flow: row;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        margin: calc(var(--offset-gap)* -1);
        padding: var(--offset-gap);
        max-width: var(--client-width,100vw);
        .dialog & {
          margin: calc(var(--dialog-spacing)* -1);
          padding: var(--dialog-spacing);
          max-width: calc(var(--client-width,100vw) - 40px);
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &__field-header {
    letter-spacing: .2px;
    display: grid;

    h1,
    h2 {
      font-size: 1.4rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      flex-flow: wrap;
      gap: 5px 10px;

      @include xs {
        font-size: 1.25rem;
      }
    }

    header,
    h3 {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      gap: 5px 10px;
      font-size: 1.2rem;
      font-weight: 500;

      @include xs {
        font-size: 1.125rem;
      }
    }

    &.--aside-button {
      @include sm {
        align-items: center;
        flex-direction: row-reverse;
      }

      @include max(500) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__error {
    font-size: 0.9375rem;
    display: grid;
    position: relative;
    .form.--smaller & {
      font-size: calc(0.9375rem * .85);
    }
    br {
      display: none;
    }

    &>* {
      text-decoration: none;
      letter-spacing: 0.3px;
      padding: 6px 10px 6px;
      position: relative;
      padding-left: 35px;
      @include trns;

      &:before {
        content: "";
        content: "" / " error icon";
        background: var(--icon-error-tip);
        width: 14px;
        height: 14px;
        position: absolute;
        top: 10px;
        left: 10px;
        opacity: 1;
        @include trns;
      }

      &:after {
        opacity: 0;
        content: "";
        content: "" / " error icon";
        background: var(--icon-success-tip);
      }
    }

    &:before {
      content: "";
      width: calc(100% + var(--error-container-spacing) * 2);
      height: 100%;
      position: absolute;
      background-color: var(--error-invalid-color);
      top: 0;
      left: calc(var(--error-container-spacing) * -1);
      @include trns;
    }

    &:last-child {
      &:before {
        height: calc(100% + var(--error-container-spacing) * 1);
        border-radius: 0 0 var(--ui-roundness, 0px) var(--ui-roundness, 0px);
      }
    }

    &:first-child {
      &:before {
        top: unset;
        bottom: 0px;
        height: calc(100% + var(--error-container-spacing) * 1);
        border-radius: var(--ui-roundness, 0px) var(--ui-roundness, 0px) 0 0;
      }
    }

    &:only-child {
      &:before {
        top: unset;
        bottom: calc(var(--error-container-spacing) * -1);
        height: calc(100% + var(--error-container-spacing) * 2);
        border-radius: var(--ui-roundness, 0px) var(--ui-roundness, 0px);
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        width: calc(100% - 45px);
        background-color: var(--error-invalid-color);
        z-index: 1;
        height: 1px;
        position: absolute;
        bottom: 0px;
        left: 35px;
      }
    }

    &.--resolved {
      --resolved: 1;

      &:after,
      &:before {
        background-color: var(--error-valid-color);
      }

      &>* {
        text-decoration: line-through;
        color: var(--color-form-valid);

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__wrapper {
    @include trns;

    .form.--success:not(.--no-success-output) & {
      opacity: 0;
    }

    .form.--success.--dynamic-success:not(.--no-success-output) & {
      display: none;
    }

    .form.--success-above.--success & {
      opacity: 1;
    }

    &>.layout {
      gap: calc(var(--layout-spacing) * 1) calc(var(--layout-spacing) * 1.75);
      align-items: flex-start;

      &.--larger-space {
        gap: var(--fieldset-spacing) calc(var(--layout-spacing) * 1.75);
      }
    }

    .numbered-form::before {
      align-items: center;
      background-color: var(--background-color-dark-gray);
      border-radius: 50%;
      color: var(--color-white);
      counter-increment: step;
      content: counter(step);
      display: inline-flex;
      height: 24px;
      width: 24px;
      justify-content: center;
      margin-right: 1rem;
      position: absolute;
      right: 100%;
      text-align: center;
      vertical-align: middle;
    }

    fieldset:disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__success {
    position: absolute;
    pointer-events: none;
    background-color: var(--color-white);
    opacity: 0;
    outline: none;
    display: grid;
    @include trns;
    @include setInset();

    &.--show-above {
      position: relative;
    }

    &.--center {
      align-items: center;
    }
    #ai-message-unlock & {
      z-index: 13;
      margin: -1rem;
      padding: 1rem;
    }

    .form.--success:not(.--no-success-output) & {
      pointer-events: all;
      opacity: 1;
    }

    .form.--dynamic-success & {
      position: relative;
    }

    .form.--no-success-output & {
      display: none;
    }
  }

  &__message {
    padding: 0;
    width: 100%;
    background-color: var(--color-white);
    outline: none;

    &.--bg-grey {
      background-color: var(--background-color-gray);
    }

    .text-block {
      --scale: 1;
    }

    &.--removing {
      @include trns;
    }

    &.--info-box {
      margin-top: 1rem;
      display: flex;
    }

    &.--remove {
      scale: 0.9;
      opacity: 0;
      pointer-events: none;
    }

    .--form-message {
      &.--center {
        text-align: center;
      }


      &.--my-account {
        background-color: #f9faf9;
        border-radius: var(--ui-roundness);
        width: 90%;

        @include xs {
          width: 100%;

          .message {
            margin: 1rem auto;
          }
        }
      }

      &.--horizontal-message {
        align-items: center;
        display: flex;

        .animated-icon {
          margin: 0;
        }

        .message {

          header,
          p {
            text-align: left;
          }
        }

        &.--centered {
          justify-content: center;
        }
      }

      .--top-spacing {
        margin-top: 2rem;
      }
    }
  }
}

.animated-icon {
  width: 150px;
  height: 150px;
  display: inline-flex;
}

.form-disabled {
  position: absolute;
  width: 100%;
  height: calc(100% - 5px);
  left: 0;
  top: 5px;
  background-color: #fffc;
  display: grid;
  place-content: center;
  padding: 0 var(--offset-gap);

  .form.generate-message & {
    @include to_xs {
      place-content: center;
    }
  }

  &__box {
    position: relative;
    padding: 1.65rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: var(--ui-box-shadow);
  }
}

.form {
  &.--display-view {
    .edit-feature {
      display: none;
    }

    .display-feature {
      display: block;
    }
  }

  &.--edit-view {
    .edit-feature {
      display: block;
    }

    .display-feature {
      display: none;
    }
  }
}

.sso-affiliations {
  margin-top: -7px;

  .sso-affiliation {
    border-radius: var(--ui-roundness);
    box-shadow: inset 0 0 0 1px var(--color-gray-5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 1rem;
    font-size: 13px;

    &__icon {
      margin-right: 7px;
      display: grid;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .form.--edit-view & {
    display: none;
  }
}