@use "../../abstracts" as *;

.table {
  --gap: 7px;
  --table-divider-color: #DDD;
  --table-divider-under-color: transparent;
  --table-radius: var(--ui-roundness, 0px);
  --table-cell-inline: 12px;
  --table-cell-block: 10px;
  --table-cell-height: 40px;
  --table-header-height: 40px;
  --table-odd-background: #F7F7F7;
  --table-footer-offset: 10px;
  --table-header-adjustment: calc(var(--table-header-height) + 2px + var(--table-footer-offset));

  &.--theme-flat {
    --table-header-height: 33px;
    --table-footer-offset: 66px;
    --table-radius: 0px;
    --table-divider-color: transparent;
    --table-odd-background: transparent;
    --table-divider-under-color: #DDD;

    // --table-cell-block: 6px;
    // --table-cell-inline: 14px;
    .wizard & {
      --table-cell-inline: 10px;
    }

    &[data-key="recipients-confirm"] {
      --table-cell-inline: 0;
    }
  }

  &:not(.--table-locked) {
    .show-on-table-locked {
      display: none;
    }
  }

  &.--table-locked {
    .hide-on-table-locked {
      display: none;
    }
  }

  &__top-anchor {
    transform: translateY(calc(var(--nav-offset, 80px) * -1));
    opacity: 0;
    pointer-events: none;
  }

  &__payment {
    &-wrapper {
      display: none;

      .--table-loading & {
        display: none !important;
      }

      &.--visible {
        display: flex;
        flex-direction: column;

        @include xs {
          align-items: flex-start;
        }
      }
    }

    &-iconwrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      display: none;

      .--table-loading & {
        display: none !important;
      }

      &.--visible {
        display: flex;
      }
    }
  }

  &__wrapper {
    .table:not(.--has-search, .--has-create, .--has-actions, .--has-select, .--not-sticky) & {
      margin-top: -.6rem;
    }

    .table.--no-entries & {
      display: none;
      visibility: hidden;
    }
  }

  --top-height: 68px;

  &:not(.--has-search, .--has-actions, .--has-create),
  &:not(.--has-search, .--has-create).--has-actions:not(.--selected) {
    --top-height: 32px;
  }

  &.--table-loading {
    pointer-events: none;

    .table__cell:not(.--actions) {

      svg,
      img {
        opacity: 0;
      }
    }

    .table__row-actions,
    .table-item__actions {
      filter: saturate(0);
      opacity: .5;
    }

    .table-item__title,
    .table__cell-inner {
      color: transparent;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 20%;
        height: 60%;
        width: calc(55% + 35% * var(--random-cell, 0));
        left: 2px;
        border-radius: 3px;
        @include loader(#efefef, #f6f6f6);
      }
    }

    @include to_xs {
      .recipient-info {
        color: transparent;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 20%;
          height: 60%;
          width: calc(55% + 35% * var(--random-cell, 0));
          left: 0px;
          border-radius: 3px;
          @include loader(#efefef, #f6f6f6);
        }
      }
    }

    .table-item__greeting {
      position: relative;
      z-index: auto;

      .lazy-load {
        opacity: 0;
      }

      &:before {
        content: '';
        top: 0px;
        height: 100%;
        width: 100%;
        position: absolute;
        border-radius: 3px;
        @include loader(#efefef, #f6f6f6);
      }
    }

    .table-item__complementary,
    .table-item__subs {
      color: transparent;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 20%;
        height: 60%;
        width: calc(20% + 50% * var(--random-cell, 0));
        left: 2px;
        border-radius: 3px;
        @include loader(#efefef, #f6f6f6);
      }
    }
  }

  &__no-entries {
    display: none;
    visibility: hidden;

    .table.--no-entries & {
      display: block;
      visibility: visible;
    }
  }

  &__top {
    position: sticky;
    top: calc(var(--main-bar-height) * var(--header-sticky));

    .table.--not-sticky & {
      position: static;
    }

    .table.--no-header & {
      display: none;
    }

    .table.--theme-flat & {
      position: relative !important;
      top: unset !important;
    }

    .tabs__panels.--transitioning & {
      position: static;
    }

    z-index: 11;
    margin-bottom: var(--table-header-adjustment);
    @include trns;

    .table:not(.--has-search, .--has-actions, .--has-create) &,
    .table:not(.--has-search, .--has-create).--has-actions:not(.--selected) & {
      margin-bottom: 0px;
      z-index: 10;
    }

    .table.--has-search & {
      z-index: 12;
    }

    @include sm {
      top: calc(var(--nav-height) * var(--header-sticky));
    }

    @include xs {
      position: relative;
      top: unset;
      margin-bottom: 0;
    }

    .btn.--mini {
      min-height: var(--ui-btn-height, 36px);
    }

    &-inner {
      padding: 1rem 0;
      height: var(--top-height);
      display: flex;
      gap: calc(var(--gap)* 1.6);
      background-color: var(--color-white);
      justify-content: space-between;
      margin-bottom: calc(var(--table-header-adjustment) * -1);
      transition: all .2s var(--easing), background-color 0s;

      .table:not(.--has-search, .--has-actions, .--has-create) &,
      .table:not(.--has-search, .--has-create).--has-actions.--has-select:not(.--selected) & {
        margin-bottom: 0px;
        transition: all .2s var(--easing), background-color 0s .2s;
      }

      .table:not(.--has-search, .--has-create).--has-actions.--has-select:not(.--selected) & {
        background-color: transparent;
      }

      #gift-cards &,
      #subscriptions & {
        padding: 0;
      }

      .table:not(.--has-search, .--has-create, .--has-sort) & {
        @include xs {
          padding-bottom: 0;
        }
      }

      @include xs {
        height: auto;
        margin-bottom: 0;
      }
    }
  }

  &__filters {
    display: flex;
    flex-flow: wrap;
    gap: var(--gap);
    align-items: center;

    @include xs {
      width: 100%;
    }

    .field {
      width: 100%;

      @include to_xs {
        width: 180px;
      }

      @include to_sm {
        width: 180px;
      }

      @include to_md {
        width: 220px;
      }

      margin-bottom: 0;
    }

    .inline {
      gap: var(--gap);
      width: 100%;
      align-items: center;
      flex-flow: row;

      &__item {
        width: unset;
      }

      &>:nth-child(1) {
        flex: 1;
      }
    }
  }

  &__actions {
    display: flex;
    gap: calc(var(--gap) * 1);
  }

  &__selected {
    font-size: .8275rem;
    color: var(--color-gray-2);
    letter-spacing: .2px;
    text-wrap: nowrap;
    margin-right: 2px;

    .table:not(.--has-select) & {
      display: none;
    }
  }

  &__selected-actions {
    display: flex;
    align-items: center;
    gap: calc(var(--gap) * 1);
    opacity: 0;
    @include trns(.2s, 2);

    .table.--selected & {
      opacity: 1;
    }

    .btn-inline-action {
      white-space: nowrap;
    }
  }

  &__clear-action {
    opacity: 0;

    .btn-simple {
      font-size: 0.9375rem;
      font-weight: 400;
      text-align: right;
    }

    @include trns(.2s, 2);

    .table.--selected & {
      opacity: 1;
    }
  }

  &__content {
    border: 1px var(--table-divider-color) solid;
    border-radius: var(--table-radius);

    .table.--theme-flat & {
      border: none;
      border-radius: 0px;
    }
  }

  &__element {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    text-indent: unset;
    border-spacing: 0px;
    padding-bottom: var(--table-footer-offset);

    th,
    td {
      vertical-align: middle;

      .table.--theme-flat & {
        vertical-align: top;
      }

      text-align: left;
      position: relative;

      &:not(.table__header, .--select, .--actions) {
        padding: calc(var(--table-cell-block) + var(--additional-top, 0px)) var(--table-cell-inline) var(--table-cell-block);
      }

      &:not(:first-child, .--actions) {
        // border-left: 1px var(--table-divider-color) solid;
        box-shadow: inset 1px 0px 0 0 var(--table-divider-color);
      }

      .table.--unselectable.--has-select &:nth-child(2) {
        box-shadow: none;
      }

      .table.--theme-flat & {
        box-shadow: inset 0px -1px 0 0 var(--table-divider-under-color);
      }

      &.--select {
        .checkbox {
          padding: var(--table-cell-block);
          padding-top: calc(var(--table-cell-block) + var(--additional-top, 0px));
          width: var(--table-cell-height);
          height: var(--table-cell-height);

          .table.--theme-flat & {
            display: grid;
          }
        }
      }
    }


    thead {
      position: sticky;
      top: calc(var(--main-bar-height) * var(--header-sticky) + var(--top-height) + 1px);
      z-index: 11;
      box-shadow: 0 0 0 1px var(--table-divider-color), 5px 0 0 1px var(--color-white), -5px 0 0 1px var(--color-white);
      margin-bottom: var(--table-footer-offset);
      @include trns;

      .table:not(.--has-search, .--has-create).--has-actions:not(.--selected) & {
        box-shadow: 0 0 0 1px var(--table-divider-color), 5px 0 0 1px var(--color-white), -5px 0 0 1px var(--color-white), 0px -32px 0 1px var(--color-white);
      }

      border-radius: var(--table-radius) var(--table-radius) 0 0;

      @include sm {
        top: calc(var(--nav-height) * var(--header-sticky) + var(--top-height) + 1px);
      }

      @include xs {
        top: calc(var(--nav-height) * var(--header-sticky) + 0px);
      }

      .tabs__panels.--transitioning & {
        position: static;
      }

      .table.--not-sticky & {
        position: static;
      }

      .table.--theme-flat & {
        position: relative;
        margin-bottom: 0;
        top: unset;
      }

      tr {
        &:first-child {

          th,
          td {
            &:first-child {
              border-top-left-radius: var(--table-radius);
            }

            .table.--unselectable.--has-select &:nth-child(2) {
              border-top-left-radius: var(--table-radius);
            }

            &:last-child {
              border-top-right-radius: var(--table-radius);
            }
          }
        }
      }

      th,
      td {
        .field {
          z-index: 3;
        }

        box-shadow: 0 1px 0 0 var(--table-divider-color),
        0 -1px 0 0 var(--table-divider-color);
        background-color: var(--color-white);

        &:not(:first-child, .--actions) {
          box-shadow: inset 1px 0 0 0 var(--table-divider-color), 0 1px 0 0 var(--table-divider-color), 0 -1px 0 0 var(--table-divider-color), 0 1px 0 0 var(--table-divider-color);
        }

        &:first-child {
          box-shadow: 0 1px 0 0 var(--table-divider-color), 0 -1px 0 0 var(--table-divider-color), -1px 0 0 0 var(--table-divider-color), -5px 0 0 0 var(--color-white);
        }
      }
    }

    tbody {
      @include randomCellGenerator(5, 7);

      .table.--theme-flat & {
        tr:last-child td {
          box-shadow: none;
        }
      }

      .wizard .table.--theme-flat:not(.--has-select) &,
      .advent-cart-page .table.--theme-flat:not(.--has-select) & {
        --table-divider-under-color: transparent;
      }

      td {

        &.--select {

          .checkbox {
            padding: calc(var(--table-cell-block) + 3px + var(--additional-top, 0px)) var(--table-cell-block) calc(var(--table-cell-block) + 3px);
            width: var(--table-cell-height);
            height: calc(var(--table-cell-height) + 6px + var(--additional-top, 0px));
          }

          &::after {
            content: '';
            position: absolute;
            transform: translate3d(0, 0, 0);
            top: var(--additional-top, 0);
            left: 0px;
            width: var(--table-width, 100%);
            height: calc(100% - var(--additional-top, 0px));
            box-shadow: inset 0 0 0 1px var(--outline-color), 0 0 0 1px var(--outline-color);
            border-radius: 3px;
            z-index: 2;
            cursor: pointer;
            opacity: var(--hovered, 0);

            .table.--theme-flat & {
              display: none;
            }

            .table:not(.--init) & {
              opacity: 0;
            }
          }
        }

        &:last-child.--actions {

          &::after {
            content: '';
            --animation-out-opacity: var(--loader-opacity, 0);
            position: absolute;
            transform: translate3d(0, 0, 0);
            top: var(--additional-top, 0);
            right: 0px;
            width: var(--table-width, 100%);
            height: calc(100% - var(--additional-top, 0px));
            background-color: var(--loader-color, #0000);
            background-image: linear-gradient(-45deg,
                var(--loader-progress-color, #0004) 25%,
                transparent 25%,
                transparent 50%,
                var(--loader-progress-color, #0004) 50%,
                var(--loader-progress-color, #0004) 75%,
                transparent 75%,
                transparent);
            background-size: 100px 100px;
            animation: fadeIn .2s linear forwards, move 1s linear infinite;
            overflow: hidden;
            border-radius: 3px;
            z-index: 0;
            display: var(--loader-display, none);
            pointer-events: none;

            .table.--theme-flat & {
              display: none;
            }
          }

          &::before {
            content: '';
            position: absolute;
            transform: translate3d(0, 0, 0);
            top: var(--additional-top, 0);
            right: 0px;
            width: var(--table-width, 100%);
            height: calc(100% - var(--additional-top, 0px));
            border-radius: 3px;
            z-index: 1;
            box-shadow: 0 0 0 1px var(--loader-color), inset 0 0 0 1px var(--loader-color);
            opacity: var(--loader-outline-opacity, 0);
            @include trns(.2s, 2);

            .table.--theme-flat &,
            .table.--no-js & {
              display: none;
            }
          }
        }
      }

      tr {
        --outline-color: var(--color-gray-1);

        @include mouse {
          &:hover {
            --hovered: 1;
          }
        }

        &.--loading {
          --hovered: 1;
          --loader-opacity: 0.065;
          --loader-display: block;

          &.--remove {
            --outline-color: var(--color-primary-lighter);
            --loader-proggress-color: var(--color-primary-lighter);
          }
        }

        &.--highlight {
          --loader-opacity: .1;
          --loader-display: block;
          --loader-outline-opacity: 1;

          &.--remove {
            --outline-color: var(--color-primary-lighter);
            --loader-color: var(--color-primary-lighter);
          }

          &.--update {
            --outline-color: var(--color-form-info);
            --loader-color: var(--color-form-info);
          }

          &.--add {
            --outline-color: var(--color-form-valid);
            --loader-color: var(--color-form-valid);
          }
        }

        &:nth-child(1) {
          td {
            --additional-top: 2px;

            .table.--theme-flat & {
              --additional-top: 0px;
            }

            .table.--theme-flat:not(.--has-select, #recipients-confirm) & {
              @include to_xxs {
                --additional-top: 10px;
              }
            }

          }
        }

        position: relative;

        &:nth-child(even) {
          td {
            background-color: var(--table-odd-background);

            .field.--checkbox {
              --ui-input-outline: #8F8F8F;
            }
          }
        }

        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: var(--table-radius);
            }

            &:last-child {
              border-bottom-right-radius: var(--table-radius);
            }

            .table.--unselectable.--has-select &:nth-child(2) {
              border-bottom-left-radius: var(--table-radius);
            }
          }
        }
      }
    }

    .table.--theme-flat & {
      td.--select {
        padding-left: 0px;
        width: 30px;

        .checkbox {
          padding-left: 0px;
          width: 30px;
        }
      }

      @include xxs {
        display: grid;
        padding-bottom: 0px;

        thead {
          display: none;
        }

        tbody,
        td,
        th,
        tr {
          display: grid;
        }

        tr {
          position: relative;
          padding: 1rem 0;
          padding-right: 48px;
          border-top: 1px var(--ui-divider-color) solid;
          gap: 10px;

          .table__cell.--actions {
            position: absolute;
            top: 1rem;
            right: 0px;
            height: calc(100% - 2rem);
            width: 38px;

            .table__row-actions {
              padding-top: 0;

              .btn-action {
                margin-right: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: var(--ui-roundness);

                &::after {
                  border-radius: var(--ui-roundness);
                  opacity: .08;
                  transform: scale(1);
                }

                &:active {
                  &:after {
                    opacity: .14;
                  }
                }
              }
            }
          }

          td {
            // padding-top: 0;
            // padding-bottom: 0;
            min-height: unset;
            height: auto;
            padding: 0px;

            .table__cell-inner {
              min-height: unset;
              padding: 0;
              margin-left: 0;
              margin-right: 0;
            }

            &>* {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__cell-inner {
    &.--input {
      z-index: 4;
      padding-top: 0;
      margin-left: calc(var(--table-cell-inline) * -1);
      padding-left: 0px;

      .--email & {
        margin-right: calc(var(--table-cell-inline) * -1);
        padding-right: 0px;
      }

      .field {
        width: 100%;

        .table.--theme-flat.--has-select & {
          &:not(:hover, .--error) {
            --field-outline: transparent;
          }
        }
      }
    }
  }

  &__sort-trigger {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    margin: 0;
    z-index: 1;
    background-color: transparent;
    border: none;
    padding: var(--table-cell-block) var(--table-cell-inline);
    padding-right: calc(var(--table-cell-inline) * .85);
    letter-spacing: .2px;
    color: var(--color-gray-2);
    font-size: 0.7725rem;
    @include trns;

    .table.--theme-flat:not(.--has-select) & {
      padding-left: 0;
    }

    @include mouse {
      [aria-sort] &:hover {
        color: var(--color-gray-1);
        --active: 1;
      }
    }

    [aria-sort] &:active {
      color: var(--color-gray-1);
      --active: 1;
    }

    [aria-sort] &:focus-visible {
      color: var(--color-gray-1);
      --active: 1;
    }

    [aria-sort] &.--active {
      --active: 1;
      color: var(--color-gray-1);
    }

    [aria-sort] &.--descending {
      --descending: 1;
    }

    [aria-sort] &.--ascending {
      --ascending: 1;
    }

    * {
      pointer-events: none;
    }

    .footnote-anchor {
      pointer-events: all;
      color: var(--color-base);
    }

  }

  div.table__sort-trigger {
    cursor: auto;
  }

  &__sort-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 -1px;
    padding: 1px 1px 0;
  }

  &__sort-icon {
    margin-left: calc(0px + var(--active, 0) * 6px);
    display: flex;
    min-width: calc(0px + var(--active, 0) * 6px);
    width: calc(0px + var(--active, 0) * 6px);

    &:before {
      content: '';
      opacity: var(--active, 0);
      display: block;
      width: 5px;
      height: 5px;
      border-bottom: 1px currentColor solid;
      border-right: 1px currentColor solid;
      transform: translateY(calc(var(--ascending, 0) * 3px)) rotate(calc(45deg + var(--ascending, 0) * 180deg));
      transition: transform .2s var(--easing);
    }
  }

  &__announce-no-results {
    color: var(--color-gray-1);
    box-shadow: inset 0 0 0 1px var(--color-gray-5);
    background-color: var(--color-gray-7);
    padding: 8px var(--field-spacing) 7px;
    line-height: 1.3em;
    border-radius: var(--field-roundness);
    position: absolute;
    font-size: 0.825rem;
    letter-spacing: 0.1px;
    top: calc(100% + 5px);
    max-width: 100%;
    pointer-events: none;
    opacity: 0;
    transform-origin: top left;
    transform: scale(.9) translateY(-5px);
    word-wrap: break-word;
    @include trns(.2s, 2);
    z-index: 1;

    &.--active {
      pointer-events: all;
      transform: scale(1) translateY(0px);
      opacity: 1;
    }

    &.--remove {
      transform: scale(.9) translateY(-5px);
      opacity: 0;
    }

    strong {
      font-weight: 500;
    }
  }

  &__header {
    height: var(--table-cell-height);
    padding: 0;
    width: calc(100% * var(--column-weight, 1));

    .table.--theme-flat & {
      height: 34px;

      .checkbox {
        padding-top: 4px;
        height: 34px;
      }
    }

    &.--select {
      width: calc(20px + var(--table-cell-block) * 2);

      .table.--has-selection-limit & {
        .field {
          display: none;
        }
      }

      .table[data-type="group"].--has-selection-limit & {
        width: 0px;

        .field {
          display: none;
        }
      }
    }

    &.--actions {
      width: calc(var(--table-cell-block) + 40px * var(--actions-count, 2));

      .table.--theme-flat & {
        width: calc(var(--table-cell-block) + 30px * var(--actions-count, 2));
      }
    }

    &-inner {
      display: block;
    }

    &[aria-sort] {
      @include mouse {
        &:hover {
          background-color: var(--color-gray-7);
        }
      }

      &:active {
        background-color: var(--color-gray-6);
      }
    }
  }

  &__row-actions {
    position: absolute;
    right: var(--table-cell-block);
    height: calc(100% - var(--additional-top, 0px));
    top: var(--additional-top, 0px);
    display: flex;
    align-items: center;
    z-index: 3;

    .table.--theme-flat & {
      right: 0px;
      padding-top: var(--table-cell-block, 0px);
      align-items: flex-start;
    }

    svg {
      width: 18px;
    }

    button {
      margin-right: -2px;
    }
  }

  &__search-match {
    position: relative;
    color: var(--color-base);

    &:before {
      content: '';
      z-index: -1;
      top: 0px;
      width: 100%;
      position: absolute;
      border-radius: 3px;
      background-color: var(--color-warning-faded);
      box-shadow: 0 0 0 2px var(--color-warning-faded);
      height: 100%;

      .table-item & {
        border-radius: 5px;
        box-shadow: 2px 0 0 0 var(--color-warning-faded), -2px 0 0 0 var(--color-warning-faded);
      }
    }
  }

  &__cell {
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: .2px;
    min-height: calc(var(--table-cell-height) + 6px);
    height: calc(var(--table-cell-height) + 6px);

    &>* {
      tr.--offsite & {
        display: none;
        visibility: hidden;
      }
    }

    &.--percent_savings {
      color: var(--color-primary-lighter);
    }

    .field {
      z-index: 3;
    }

    &-inner {
      padding: 2px 2px 0;
      z-index: auto;
      position: relative;
      margin: 0 -2px;
      display: flex;
      align-items: center;
      min-height: calc(var(--table-cell-height) + 6px - var(--table-cell-block) * 2 - var(--additional-top, 0px));
    }

    &-truncate {
      margin: 0 -2px;
      padding: 0 2px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.--actions {
      .table__cell-inner {
        height: unset;
        padding: 0;
      }
    }

    &.--select {
      .table[data-type="group"].--has-selection-limit & {
        width: 0px;

        .field {
          display: none;
        }
      }
    }

    .table-item__greeting {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 3;

      &>* {
        .--table-loading & {
          color: transparent;
        }

        width: 100%;

        &.lazy-load {
          width: 60%;
        }
      }

      &-title {
        max-height: 2.8em;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        &.--is-sdk {
          visibility: hidden;
          height: 0;
        }
      }

      .lazy-load__img.--is-sdk {
        aspect-ratio: 1/1;
      }

    }

    .table-item__digital-gift {
      align-items: center;
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 2fr;
      position: relative;
      z-index: 3;
    }

    .table-item__digital-gift-recipient {
      .recipient {
        color: var(--color-gray-2);
        font-weight: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .recipient-info {
        display: flex;
        gap: 5px;
        position: relative;
        z-index: 3;

        .btn-action.--mini {
          height: 30px;
          min-width: 30px;
          right: 0;
          margin-top: -7px;
        }
      }

      .btn-simple {
        &:not(.--visible) {
          display: none !important;
          visibility: hidden !important;
        }

        .--table-loading & {
          color: transparent !important;
        }
      }
    }
  }

  &__mobile-filters {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: var(--gap);
    width: 100%;

    .table:not(.--has-create) & {
      flex-flow: row;
    }

    .table.--theme-flat & {
      display: none !important;
    }
  }

  &__mobile-filter {
    flex-grow: 1;

    .btn-inline-action {
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    &.--order {
      max-width: 85px;
    }

    .field {
      --field-spacing: 1rem;
    }
  }

  &__footer {
    .table.--theme-flat:not(.--has-select) & {
      margin-top: 10px;

      @include xxs {
        margin-top: 0px;
      }
    }

    .table.--table-locked & {
      body:not(.--is-signed-in) & {
        display: none;
      }
    }

    margin-bottom: -1rem;
    padding: 1rem 0;
    // position: sticky;
    // z-index: 5;
    // bottom: 6px;
    box-shadow: inset 0 1px 0 0 var(--ui-divider-color),
    0 3px 0 3px var(--color-white);
    background: var(--color-white);

    &-actions {
      display: flex;
      gap: var(--gap);

      @include xxs {
        flex-flow: wrap;
      }

      .table.--table-locked & {
        button[data-action="create"] {
          display: none;
        }

        button[data-action="add"] {
          background-color: var(--color-gray-6);

          @include mouse {
            &:hover {
              background-color: var(--color-gray-5);
            }
          }

          &:active {
            @include trns(0s);
            background-color: var(--color-gray-4-5);
          }
        }
      }

      .field.--checkbox {
        margin-left: auto;

        .checkbox {
          padding: 0;

          &__label {
            align-items: center;
          }

          &__text {
            margin-left: 12px;
            line-height: 1.3em;
          }
        }
      }

      @include xxs {
        .btn-inline-action {
          flex-grow: 1;
          justify-content: center;
        }
      }
    }
  }

  &__mobile-actions {
    position: sticky;
    z-index: 5;
    bottom: 0px;
    background: var(--color-white);
    display: flex;
    justify-content: flex-end;
    height: 0px;
    margin-bottom: -1rem;
    overflow-y: hidden;
    margin: 0 -1rem;
    padding: 0 1rem;
    @include trns(.2s, 2);

    .table.--is-send & {
      display: none !important;
    }

    .table:not(.--has-actions) & {
      display: none !important;
    }

    .dialog & {
      display: none !important;
    }

    .table.--selected & {
      height: 68px;
    }

    .table__selected-actions {
      padding: 1rem 0;
      box-shadow: inset 0 1px 0 0 var(--ui-divider-color);
      justify-content: space-between;
      flex-flow: wrap;
      width: 100%;

      [data-action=clear] {
        display: none;
      }
    }

    .table__selected {
      margin-right: auto;
      font-size: .875rem;
    }

    .btn-inline-action {
      @include max(370) {
        padding: 1px 0.7rem 0;
      }
    }
  }

  &__list {

    // margin-top: -.8rem;
    &-content {
      @include randomCellGenerator(5, 1);
    }

    #recipients-confirm & {
      margin-bottom: -0.5275rem;
    }
  }

  &__list-top {
    padding: .8rem 0;
    z-index: 6;
    position: sticky;
    top: calc(var(--nav-height) * var(--header-sticky) - 1px);

    .table.--has-selection-limit & {
      display: none;
    }

    .tabs__panels.--transitioning & {
      position: static;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    box-shadow: inset 0 -1px 0 0 var(--ui-divider-color),
    10px 0 0 0 var(--color-white),
    -10px 0 0 0 var(--color-white);

    .table:not(.--has-select) & {
      display: none;
    }

    .field {
      margin-bottom: 0;
    }

    .checkbox__text {
      font-size: 0.9375rem;
      line-height: 20px;
    }
  }

  &__pagination {
    display: flex;
    margin: 1rem auto;
    justify-content: center;
    gap: 6px;
    flex-wrap: wrap;

    button {
      text-decoration: none;
      text-align: center;
      font-weight: 500;
      font-size: 13px;
      border-radius: var(--ui-roundness, 0);
      border: none;
      background-color: var(--color-gray-6);
      min-width: 36px;
      height: 36px;
      cursor: pointer;
      @include trns;

      @include mouse {
        &:hover {
          @include trns(.1s);
          background-color: var(--color-gray-5);
        }
      }

      &:active:not(.--active) {
        @include trns(0s);
        background-color: var(--color-gray-4-5);
      }

      &.--active {
        background-color: var(--color-jet);
        color: white;
      }
    }
  }

  &.--member-benefits {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    .table__content {
      border: none;
    }

    thead {
      box-shadow: none;

      th {
        box-shadow: none;
      }
    }

    tbody {
      border: 1px var(--table-divider-color) solid;
      border-radius: var(--table-radius);
    }

    .table__cell {
      text-align: center;
    }

    .table__header .table__sort-trigger {
      justify-content: center;
      font-size: .85rem;
    }

    .icon-cell {
      display: flex;
      align-items: center;
      font-size: 0.785rem;
      text-align: left;

      .table-icon {
        padding-left: .5rem;
      }
    }

    .checkmark-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.table-item {
  --table-item-spacing: .875rem;
  display: grid;
  grid-template-areas: 'hd' 'ev';
  grid-template-columns: 1fr;
  gap: 0px;
  position: relative;
  padding: var(--table-item-spacing) 0;

  .table.--has-select & {
    grid-template-areas: 'cb hd' 'cb ev';
    grid-template-columns: 30px 1fr;
  }

  .table.--has-actions & {
    grid-template-areas: 'hd ac' 'ev ev';
    grid-template-columns: 1fr calc(var(--actions-count) * 40px);
  }

  .table.--has-select.--has-actions &,
  .table[data-key="groups"].--has-select.--is-send & {
    grid-template-areas: 'cb hd ac' 'cb ev ev';
    grid-template-columns: 30px 1fr calc(var(--actions-count, 1) * 40px);
  }

  li:not(:first-child) & {
    border-top: 1px var(--ui-divider-color) solid;
  }

  &__content {
    grid-area: hd;
  }

  &__events {
    grid-area: ev;
    margin-top: .675rem;
    border-radius: var(--ui-roundness, 0px);
    background-color: var(--color-gray-7);
    padding: 8px 12px;

    .table-item.--no-events & {
      display: none;
    }
  }

  &__event {
    letter-spacing: .2px;
    font-size: 0.875rem;
    padding: 4px 0;
    display: flex;
    align-items: flex-start;

    &-icon {
      margin-right: 10px;
      display: flex;
    }

    strong {
      font-weight: 500;
    }
  }

  &__checkbox {
    grid-area: cb;
    position: relative;

    .table:not(.--has-select) & {
      display: none;
    }

    .field,
    .checkbox {
      position: static;
    }

    label {
      &:before {
        content: '';
        width: calc(100% + var(--offset-gap) + 100px);
        height: calc(100% + var(--table-item-spacing) * 2);
        left: calc(var(--offset-gap) * -1);
        z-index: 2;
        top: calc(var(--table-item-spacing) * -1);
        cursor: pointer;
        position: absolute;
      }
    }
  }

  &__actions {
    grid-area: ac;
    display: grid;
    align-items: flex-start;
    justify-content: end;
    margin-right: -6px;

    .table:not(.--has-actions) & {
      display: none;
    }

    .table.--is-send & {
      display: grid;
    }

    ul {
      display: flex;
    }
  }

  &__title {
    font-size: 1.0625rem;
    letter-spacing: .2px;
    font-weight: 500;
    overflow-wrap: anywhere;

    &.--centered {
      text-align: center;
      margin-bottom: 15px;
    }

    &.--spacing {
      display: block;
      padding-bottom: .75rem;
    }
  }

  &__complementary {
    font-size: .875rem;
    overflow-wrap: anywhere;
    color: var(--color-gray-2);
    letter-spacing: .2px;
  }

  &__subs {
    &-label {
      font-weight: 400;
      letter-spacing: .32px;

      .--table-loading & {
        color: transparent;
      }
    }

    &-value {
      font-weight: 500;
      letter-spacing: .32px;

      .--table-loading & {
        color: transparent;
      }

      &.--truncate {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn-simple {
        &:not(.--visible) {
          display: none !important;
          visibility: hidden !important;
        }

        .--table-loading & {
          color: transparent !important;
        }
      }

      .recipient-info {
        display: flex;
        position: relative;

        p {
          padding-right: 30px;
        }

        .btn-action.--mini {
          height: 23px;
          min-width: 23px;
          position: absolute;
          right: 0px;

          .--table-loading & {
            display: none;
          }
        }
      }
    }

    @include xs {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      max-width: 100%;

      &:not(:last-child) {
        padding-bottom: 0.4rem;
      }
    }
  }

  &__greeting {
    .table__list & {
      width: 50%;
      margin: .5rem 0 1rem;
    }
  }

  &.--border {
    @include xs {
      border: 1px solid var(--ui-divider-color);
      border-radius: var(--ui-roundness);
      margin-top: 1rem;

      .table-item__content {
        display: grid;
        padding: 0 1rem;
      }

    }
  }
}

.table-dynamic-pagination {
  display: grid;
  grid-template-columns: 36px auto 36px;
  gap: var(--gap, 0px);
  place-content: center;
  margin-top: 1rem;
  position: relative;

  &__pages {
    display: flex;
    justify-content: center;
    gap: 5px;

    input {
      min-width: 100px;
    }
  }
  .listbox {
    z-index: 10;
  }
  .btn-action {
    font-size: 13px;
    font-weight: 500;

    &.--primary {
      background-color: var(--color-gray-1) !important;
    }

    border-radius: var(--ui-roundness);

    &:after {
      border-radius: var(--ui-roundness);
    }

    &[aria-disabled=true] {
      opacity: .5;
      pointer-events: none;
    }
  }
}

.table-header {
  display: grid;
  padding: 1.2rem 0 0.3rem;
  justify-content: space-between;
  grid-template-columns: 1fr;

  &__info {
    max-width: 320px;
    padding-top: 0.7rem;
  }

  .dialog & {
    padding: 1.8rem 0 0.3rem;
  }

  &__title {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--gap);

    h2 {
      overflow-wrap: anywhere;
    }
  }

  &__actions {
    display: flex;
    gap: 10px;
    padding: 0.6rem 0;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 0rem 2rem;
  }


  &__back-holder {
    margin-left: -10px;
    margin-top: -4px;
  }

  .text-block.--inline {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr auto;

    button {
      margin: -2px 0;
    }
  }

  .btn {
    --ui-btn-spacing: 1.35rem;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100px 0;
  }
}