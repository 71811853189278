@use "../../abstracts" as *;

.field {
  --field-spacing: var(--ui-input-spacing, 1rem);
  --field-height: var(--ui-input-height, 42px);
  --field-size: var(--ui-input-size, 1rem);
  --field-color: var(--ui-input-color, var(--color-gray-1));
  --field-roundness: var(--ui-roundness, 0px);
  --field-inset-button-height: calc(var(--field-height) * 0.64);
  --field-outline: var(--ui-input-outline, var(--color-gray-3));
  --field-outline-focused: var(--ui-input-outline-focused, var(--color-gray-1));
  --field-checkbox-size: var(--ui-checkbox-size, 24px);
  --field-option-size: 22px;
  --field-tooltip: var(--ui-tooltip-size, 14px);
  --field-status: 0;
  --field-top-offset: 2px;
  --field-icon-size: 24px;
  --field-asteriks: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='8' fill='none' viewBox='0 0 6 8'%3E%3Cpath fill='%23333333' d='M5.42 4.647 4.309 4l1.082-.636a.667.667 0 1 0-.656-1.162l-1.068.634v-1.28a.667.667 0 0 0-1.334 0v1.28l-1.077-.634a.667.667 0 1 0-.667 1.151L1.7 4l-1.1.636a.667.667 0 1 0 .656 1.16l1.077-.632v1.258a.667.667 0 1 0 1.334.022v-1.28l1.077.634a.667.667 0 1 0 .667-1.151h.009Z'/%3E%3C/svg%3E");
  display: grid;
  position: relative;
  @include setTransition(.2s, 2, opacity);

  &.--inactive {
    opacity: .7;
    pointer-events: none;
  }



  .--inactive &.--inactive {
    opacity: 1;
    pointer-events: all;
  }

  &.--mini {
    --field-top-offset: 2px;
    --field-spacing: calc(var(--ui-input-spacing, 1rem) * .75);
    --field-height: var(--ui-input-height, 36px);
    --field-size: calc(var(--ui-input-size, 1.0625rem) * .85);
    --field-icon-size: 20px;
    --field-checkbox-size: 20px;
    --field-error-tip-size: 11px;

    @include xxs {
      --field-top-offset: 2px;
      --field-spacing: calc(var(--ui-input-spacing, 1rem) * .75);
      --field-height: var(--ui-input-height, 40px);
      --field-size: var(--ui-input-size, 1rem);
      --field-icon-size: 20px;
      --field-checkbox-size: 20px;
      --field-error-tip-size: 11px;
    }

    .check-icon {
      width: 14px;
      height: 7px;
    }
  }

  &.--medium,
  &__label.--medium {

    --field-top-offset: 2px;
    --field-spacing: calc(var(--ui-input-spacing, 1rem) * .75);
    --field-height: var(--ui-input-height, 40px);
    --field-size: var(--ui-input-size, 1rem);
    --field-icon-size: 20px;
    --field-checkbox-size: 20px;
    --field-error-tip-size: 11px;

    .check-icon {
      width: 14px;
      height: 7px;
    }
  }

  &.--larger-gap {
    margin-bottom: 10px !important;
  }

  &+&:not(.--option) {
    margin-top: var(--layout-spacing);
  }

  &.--file {
    justify-content: start;
  }

  .btn-action {
    z-index: unset;
  }

  @include to_xs {
    &.--mini-to-xs {
      --field-top-offset: 2px;
      --field-spacing: calc(var(--ui-input-spacing, 1rem) * .75);
      --field-height: var(--ui-input-height, 36px);
      --field-size: calc(var(--ui-input-size, 1rem) * .85);
      --field-icon-size: 20px;
      --field-checkbox-size: 20px;

      .check-icon {
        width: 14px;
        height: 7px;
      }
    }
  }

  &.--valid {
    --field-status: 1;
    --field-outline: #{rgba($color-form-valid, 0.75)};
    --field-outline-focused: var(--color-form-valid);
  }

  &.--error {
    --field-status: 1;
    --field-outline: #{rgba($color-form-error, 0.6)};
    --field-outline-focused: var(--color-form-error);
  }

  &.--plain-field {
    text-decoration: underline;
    text-underline-offset: 3px;

    .checkbox__text {
      cursor: pointer;
      margin-left: 0;
    }
  }

  .--music-list &.--option {
    display: flex;
    flex-flow: column;

    @include xxs{
      min-width: max-content;
    }

    .option {
      height: 100%;
    }
  }

  &__inset-label {
    display: flex;
    justify-content: center;
    background: var(--color-gray-6);
    padding: 3px 5px;
    min-width: 70px;
    text-align: center;
    border-radius: 34px;
    margin-left: 6px;
    letter-spacing: 0.2px;
    font-size: 0.7rem;
    color: var(--color-gray-1);
  }

  &:not(:last-child) {
    margin-bottom: calc(var(--inter-content-spacing) * 1);
  }

  &.--select {
    --field-icon-offset: calc(var(--field-icon-size) + 0px);
    --field-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23333' d='M15.846 9.526 12 6.014 8.154 9.526m0 4.948L12 17.986l3.846-3.512'/%3E%3C/svg%3E");

    @include xxs {
      --field-icon-offset: calc(var(--field-icon-size) - 5px);
    }
  }

  &.--as-btn {
    --field-size: var(--ui-input-size, .795rem);
    --field-spacing: var(--ui-input-spacing, .875rem);
    @include xxs {
      --field-size: var(--ui-input-size, .795rem);
      --field-spacing: var(--ui-input-spacing, .875rem);
      --field-height: var(--ui-input-height, 36px);
      --field-icon-offset: var(--field-icon-size);
    }
  }

  .form.--display-view & {
    .field__wrapper {
      transform: translateY(-8px) translateX(calc(var(--field-spacing) * -1));
    }
  }

  &.--always-read-only {
    --field-spacing: 0rem;
  }

  .form.--display-view &,
  &.--always-read-only {
    --field-icon-size: 0px;
    --field-outline: transparent;
    --field-asteriks: none;

    .field__label {
      cursor: auto;
      z-index: 1;
    }

    .field__input {
      &:hover {
        &:before {
          box-shadow: none;
          opacity: 0;
        }
      }

      &:focus-within {
        box-shadow: none;

        &:before {
          box-shadow: none;
          opacity: 0;
        }
      }

      input {
        cursor: text;
      }
    }
  }

  &__placeholder {
    position: absolute;
    font-weight: 400;
    color: var(--color-gray-2);
    line-height: var(--field-height);
    font-size: var(--field-size);
    padding: var(--field-top-offset) calc(var(--field-spacing) + var(--field-icon-offset,0px)) 0 var(--field-spacing);
    pointer-events: none;
    letter-spacing: .1px;
  }

  &__instructions {
    font-size: 12px;
    letter-spacing: .3px;
    color: var(--color-gray-2);

    &:last-child {
      margin-top: 0.4rem;
      .conditional-field__inner & {
        margin-top: 0rem;
        padding-top: .5rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: calc(0.4rem + 3px);
      #send-flow & {
        margin-bottom: 0.4rem;
      }
    }
  }

  &:not(.--checkbox, .--radio, .--option, .--range, .--custom) {

    .field__subfields,
    input,
    select,
    textarea {
      appearance: none;
      border: none;
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: var(--field-top-offset) calc(var(--field-spacing) + var(--field-icon-offset, 0px)) 0px var(--field-spacing);
      height: var(--field-height);
      font-size: var(--field-size);
      color: var(--field-color, var(--color-gray-1));
      border-radius: var(--field-roundness);
      font-weight: 500;
      letter-spacing: 0.1px;
      text-align: left;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        transition: background 1s 30000s ease, color 1s 30000s ease;
        font-weight: 500;
        appearance: none;
        letter-spacing: .1px;
        color: var(--field-color, var(--color-gray-1)) !important;
        background: transparent !important;
        box-shadow: inset 0 0px 0 1px var(--field-outline), inset 0 0 20px 20px var(--color-white),;
      }

      &.--masked {
        opacity: 0;
        position: absolute;
        pointer-events: none;
      }

      &.clone {
        display: none;
      }

      &::-webkit-textfield-decoration-container {
        display: flex;
        align-items: center;
        -webkit-user-modify: read-only;
        content: none;
      }

      -webkit-tap-highlight-color: transparent;

      &::-webkit-search-cancel-button {
        appearance: none;
      }
      &[type=button] {
        padding-top: 1px;
        overflow: hidden;
        cursor: pointer;
      }
    }

    .field__subfields,
    input,
    select {
      line-height: var(--field-height);
      outline: none;
      text-overflow: ellipsis;
    }

    textarea {
      --field-top-offset: 10px;
      padding-bottom: 10px;
      resize: vertical;
      min-height: 175px;
      max-height: 400px;
      line-height: 1.6666em;
      letter-spacing: 0.5px;
      outline-offset: 1px;
      clip-path: inset(0px round var(--field-roundness));

      &.--small-container {
        min-height: 155px;
        line-height: 1.4em;
        font-size: 1rem;

        @include sm {
          min-height: 165px;
        }

        @include xs {
          min-height: 175px;
        }
      }
    }
  }

  &.--rich-text {
    textarea {
      min-height: 230px;
      max-height: 230px;
    }
  }

  &__label {
    .form:not(.--all-required) .field.--required:not(.--no-label) & {
      &:after {
        content: "";
        width: 6px;
        min-width: 6px;
        height: 17px;
        position: relative;
        margin-left: 4px;
        background-image: var(--field-asteriks);
        background-position: center;
        background-repeat: no-repeat;
        opacity: .55;
      }
    }
  }

  &__upload {
    .field.--error & {
      button {
        --background: var(--color-primary-lighter);
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;

    &.--spaced {
      margin-bottom: 6px;
    }

    .field.--no-label & {
      pointer-events: none;
    }
  }

  &>.field__output {
    box-shadow: 0px -6px 0 0 rgba($color-form-error, 0.07);
    background-color: rgba($color-form-error, 0.07);
    border-radius: 0 0 var(--field-roundness) var(--field-roundness);

    &:first-child {
      border-radius: var(--field-roundness) var(--field-roundness) 0 0;
    }
  }

  &>.field__output .field__error {
    width: 100%;
    display: flex;
    padding: calc(var(--field-spacing) * .4 + 1px) var(--field-spacing) calc(var(--field-spacing) * .4);
    position: relative;
    align-items: center;
    justify-content: space-between;
    font-size: var(--field-error-size, 12px);
    font-weight: 500;
    letter-spacing: .25px;
    text-align: left;
  }

  &__output {
    color: var(--color-form-error);
    height: var(--js-height, auto);
    display: block;
    font-size: calc(var(--field-size) * .9);
    overflow: hidden;
    @include trns;

    .field.--no-output & {
      pointer-events: none;
      overflow: hidden;
      width: 1px;
      clip: rect(0 0 0 0);
      clip-path: inset(100%);
      white-space: nowrap;
    }
  }

  &__input {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: minmax(1px, 1fr);
    box-shadow: inset 0 0px 0 1px var(--field-outline),
      0 0px 0 0px var(--field-outline);
    border-radius: var(--field-roundness);
    background-color: var(--field-background, var(--color-white));
    @include trns(0.1s);
    .field.--create-password &,
    .field.--password & {
      input {
        line-height: 1;
        padding-right: calc(70px + var(--field-spacing));

        &:-webkit-strong-password-auto-fill-button {
          display: none;
          appearance: none;
        }
      }
    }

    .field.--textarea & {
      padding: 1px;
      .tox.tox-tinymce.tox-platform-touch {
        .wizard-panel:not(.--active, .--activating,.--deactivating) &{
          visibility: hidden !important;
        }
      }
    }

    &:before {
      content: "";
      @include setInset;
      @include trns(0.1s);
      border-radius: var(--field-roundness);
      box-shadow: 0 0 0 0px var(--field-outline-focused);
      opacity: 0;
      pointer-events: none;
    }

    &:after {
      content: "";
      width: var(--field-icon-size);
      height: var(--field-icon-size);
      position: absolute;
      right: 10px;
      top: calc(50% - var(--field-icon-size) / 2 + 1px);
      background: var(--field-icon, none);
      background-size: contain;
      background-position: center;
      pointer-events: none;

      .field.--medium & {
        right: 7px;
      }
    }

    @include mouse {
      &:hover {
        &:before {
          box-shadow: 0 0 0 3px var(--field-outline-focused);
          opacity: 0.2;
        }
      }
    }

    &:focus-within {
      box-shadow: inset 0 0px 0 1px var(--field-outline-focused),
        0 0 0 1px var(--field-outline-focused);

      &:before {
        box-shadow: 0 0 0 0px var(--field-outline-focused);
        opacity: 0.2;
      }
    }

    body:not(.tox-dialog__disable-scroll) &.--focused {
      box-shadow: inset 0 0px 0 1px var(--field-outline-focused),
        0 0 0 1px var(--field-outline-focused);

      &:before {
        box-shadow: 0 0 0 0px var(--field-outline-focused);
        opacity: 0.2;
      }
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }

    input::-webkit-input-placeholder {
      font-weight: 400;
      color: var(--color-gray-2);

      &:focus {
        color: var(--field-color, var(--color-gray-1));
      }
    }

    input::-webkit-inner-spin-button {
      opacity: 0;
      position: absolute;
      width: 0;
      pointer-events: none;
    }

    input:-moz-placeholder {
      color: var(--color-gray-2);
      font-weight: 400;
      opacity: 1;

      &:focus {
        color: var(--field-color, var(--color-gray-1));
      }
    }

    input::-moz-placeholder {
      color: var(--color-gray-2);
      font-weight: 400;
      opacity: 1;

      &:focus {
        color: var(--field-color, var(--color-gray-1));
      }
    }

    input:-ms-input-placeholder {
      color: var(--color-gray-2);
      font-weight: 400;

      &:focus {
        color: var(--field-color, var(--color-gray-1));
      }
    }

    input:placeholder-shown {
      color: var(--color-gray-2);
      font-weight: 400;

      &:focus {
        color: var(--field-color, var(--color-gray-1));
      }
    }

    .field.--as-btn &{
      background-color: transparent;
      box-shadow: none !important;
      &::before {
        display: none;
      }
      &::after {
        right: 9px;
        filter: brightness(0.6);
      }
      input {
        &:focus-visible {
          @include trns(0s);
          box-shadow: inset 0 0 0 2px var(--color-gray-1);
        }
        background-color: var(--color-gray-6);
        @include trns;
        @include mouse {
          &:hover {
            @include trns(.1s);
            background-color: var(--color-gray-5);
          }
        }
        &:active {
          @include trns(0s);
          background-color: var(--color-gray-4-5);
        }
      }
    }
  }

  &__display {
    font-weight: 500;
    padding: .5rem 0;
  }

  &__link {
    padding-bottom: .5rem;
    margin-top: calc(0.3rem + 3px);
  }

  &.--inset-button {

    input,
    .field__subfields {
      padding-right: calc(40px + var(--field-spacing));
    }
    &.--predictive-search input {
      padding-right: calc(35px + var(--field-spacing));
    }

    &.--mini-to-xs {
      @include to_xs {
        input {
          padding-right: calc(28px + var(--field-spacing));
        }
      }
    }

    &.--mini {
      input {
        padding-right: calc(28px + var(--field-spacing));
      }
    }
  }

  &.--predictive-search {
    input::-webkit-search-cancel-button {
      appearance: none;
      height: 22px;
      width: 26px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none' viewBox='0 0 22 22'%3E%3Cpath fill='%23626262' d='M9.597 7.764a.687.687 0 0 1 .972 0l2.264 2.264 2.264-2.264a.686.686 0 1 1 .972.972L13.805 11l2.264 2.264a.688.688 0 0 1-.971.972l-2.265-2.264-2.264 2.264a.688.688 0 1 1-.972-.972L11.862 11 9.597 8.736a.687.687 0 0 1 0-.972Z'/%3E%3Cpath fill='%23626262' fill-rule='evenodd' d='M7.705 4.538a3.438 3.438 0 0 0-2.704 1.314L1.738 10.01a1.604 1.604 0 0 0 0 1.982L5 16.148a3.437 3.437 0 0 0 2.704 1.315h9.76a2.521 2.521 0 0 0 2.521-2.521V7.058a2.521 2.521 0 0 0-2.52-2.52h-9.76ZM6.083 6.7a2.062 2.062 0 0 1 1.622-.788h9.76c.633 0 1.146.513 1.146 1.145v7.884c0 .632-.513 1.146-1.146 1.146h-9.76a2.062 2.062 0 0 1-1.622-.789L2.819 11.14a.23.23 0 0 1 0-.283L6.083 6.7Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      background-position: right;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-right: -7px;
      z-index: 1;
    }
    &.--mini {
      input::-webkit-search-cancel-button {
        background-position: center;
        background-size: 21px;
        margin-right: -5px;
      }
    }
  }

  &.--cancelable-search {
    .field__button {
      &-off {
        display: flex;
        opacity: 0;
        @include trns;
      }

      &-on {
        display: flex;
        position: absolute;
        opacity: 1;
        @include trns;
      }

      &[disabled] {
        pointer-events: none;

        .field__button {
          &-off {
            opacity: 1;
          }

          &-on {
            opacity: 0;
          }
        }
      }
    }
  }

  &__button,
  .show-hide-button {
    * {
      pointer-events: none;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: calc(var(--field-height) / 2 - var(--field-inset-button-height) / 2);
    top: calc(50% - var(--field-inset-button-height) / 2);
    padding: 0px calc(var(--field-spacing) * 0.75) 0px;
    border-radius: calc(var(--field-roundness) * 0.64);
    font-size: 0.9375rem;
    height: var(--field-inset-button-height);
    border: none;
    letter-spacing: 0.2px;
    text-align: center;
    cursor: pointer;
    @include trns(.2s, 3);


    &.--large-icon {
      background-color: transparent;
      border-radius: 0 calc(var(--field-roundness) * 0.64) calc(var(--field-roundness) * 0.64) 0;
      right: 0;
      top: 0;
      padding: 0;
      width: var(--field-height);
      height: var(--field-height);

      svg {
        width: 28px;
        height: 28px;

        .field.--mini & {
          width: 24px;
          height: 24px;
        }

        @include to_xs {
          .field.--mini-to-xs & {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    @include mouse {
      &:hover {
        @include trns(.1s, 2);
        color: var(--color-gray-1);
        background-color: rgba($color-gray-1, .1);
      }
    }

    &:active {
      @include trns(0s);
      color: var(--color-gray-1);
      background-color: rgba($color-gray-1, .18);
    }
  }

  &__button-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include trns;

    .--loading & {
      opacity: 1;
    }
  }

  &__button-content {
    display: flex;
    @include trns;

    .--loading & {
      opacity: 0;
    }
  }

  .show-hide-button {
    min-width: 64px;
    font-size: .875rem;
    background-color: var(--color-gray-8);
    @include whiteBtnInteraction;
  }

  &__wrapper {
    position: relative;
    @include trns;
  }

  &__label {
    display: flex;
    font-size: calc(var(--field-size) * .925);
    letter-spacing: 0.36px;
    text-align: left;
    &-locked-icon {
      display: none;
      visibility: hidden;
    }
    &.--has-icon {
      align-items: baseline;
      svg {
        color: var(--color-gray-2);
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
      &-locked-icon {
        display: block;
        visibility: visible;
      }
    }
    [aria-hidden] {
      margin-left: 6px;
    }
  }

  &.--checkbox {
    gap: 0px;
  }

  &.--wider-xs {
    min-width: 220px;
  }
}

.field {

  &__status {
    margin-top: .55em;
    .inline__item .text-block.--smaller p{
      line-height: 1.2em;
    }
  }

  &__subfields {
    display: flex;
    padding-top: 0px !important;
    align-items: center;
    padding-left: calc(var(--field-spacing) - 5px) !important;

    div {
      padding-top: var(--field-top-offset, 1px);
      color: var(--color-gray-3);
      font-weight: 400;
      width: 0px;
      pointer-events: none;
      transform: translateX(-3px);
    }

    input[type="number"] {
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 0px;
      cursor: default;

      &[maxlength="2"] {
        width: var(--subfield-width, 35px);
        text-align-last: center;
      }

      &[maxlength="4"] {
        width: var(--subfield-width, 62px);
        padding-left: 8px;
      }
    }
  }

  &__loader {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    border-radius: calc(var(--field-roundness) - 1px);
    background-color: var(--color-white);
    display: grid;
    place-content: center;
    opacity: 1;
    z-index: 2;
    pointer-events: all;
    @include trns(.3s);

    &-spinner {
      display: grid;

      span {
        margin-bottom: .3rem;

        .field__loader.--inline & {
          margin-bottom: 0px;
          margin-right: 14px;
        }
      }

      img {
        width: 45px;
        height: 45px;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    &-content {
      display: grid;
      align-items: center;
      justify-items: center;
      gap: 0px;

      .field__loader.--inline & {
        grid-template-columns: auto 1fr;
      }
    }

    &-dots {
      position: absolute;
    }

    .field:not(.--loading) & {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__widget {
    duet-date-picker {
      .duet-date {
        &__input-wrapper {
          display: none;
        }

        @media (min-width: 35.9375em) {
          &__dialog {
            transform: translateY(10px) translateZ(0);

            &.is-active {
              transform: translateY(0px) translateZ(0);
            }
          }
        }

        &__table {
          table-layout: fixed;

          thead tr th {
            width: 20%;
            position: relative;
            padding-bottom: 20px;
            font-size: .565rem;
            color: var(--color-gray-2);

            @media (max-width: 35.9375em) {
              font-size: 0.675rem;
            }

            &:before {
              content: '';
              width: calc(100% + var(--duet-header-gap, 0px));
              height: 1px;
              background-color: var(--ui-divider-color);
              position: absolute;
              bottom: 10px;
              left: 0px;
            }

            &:first-child {
              &:before {
                left: unset;
                right: 0;
              }
            }

            &:last-child,
            &:first-child {
              --duet-header-gap: var(--duet-spacing);
            }
          }
        }

        &__mobile {
          @media (max-width: 35.9375em) {
            width: calc(100% + var(--duet-spacing) * 2);
            margin-left: calc(var(--duet-spacing) * -1);
          }
        }

        &__day {
          border-radius: var(--ui-roundness);
          width: 100%;
          height: 34px;
          padding-top: 2px;
          font-size: 0.785rem;
          position: relative;

          @media (max-width: 35.9375em) {
            height: 42px;
            font-size: 1rem;
          }

          &:not([aria-pressed="true"]):focus {
            background: transparent;
            color: var(--duet-color-text);
          }

          &[aria-pressed="true"] {
            background-color: var(--color-gray-1);
            color: var(--color-white);
            z-index: -1;
            outline-offset: 3px;
            opacity: 1;
          }

          &:focus {
            z-index: 1;
          }

          &:before {
            content: "";
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: var(--ui-roundness);
            background: var(--color-gray-1);
            @include trns;
          }

          &:not([aria-pressed="true"]) {
            @include mouse {
              &:hover {
                &:before {
                  opacity: 0.1;
                }
              }
            }

            &:active {
              &:before {
                @include trns(0s);
                opacity: 0.18;
              }
            }
          }

          &.is-today {
            color: var(--duet-color-primary);

            &:before {
              background-color: var(--duet-color-primary);
            }

            &:focus {
              color: var(--duet-color-primary);
            }

            &[aria-pressed="true"] {
              color: var(--duet-color-text-active);
            }
            &:after {
              content: "";
              position: absolute;
              border-radius: 4px;
              opacity: 1;
              bottom: 1px;
              left: calc(50% - 2px);
              width: 4px;
              height: 4px;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: 4px solid currentColor;
              transform: rotate(-90deg);
            }
          }

          &.is-outside {
            background: transparent;
            pointer-events: none;
            opacity: 0.67;
          }
          &:not(.is-month):not([aria-pressed=true]) {
            opacity: 0.67;
          }
        }

        &__next,
        &__prev,
        &__day,
        &__close {
          box-shadow: none;

          &:focus-visible {
            outline-style: auto;
            outline-width: 1px;
            outline-color: var(--color-gray-1);
          }
        }

        &__close {
          @media (min-width: 35.9375em) {
            pointer-events: none;
          }
        }

        &__next,
        &__prev,
        &__close {
          background-color: var(--color-white);
          @include trns;

          &:hover {
            background-color: var(--color-gray-6);
          }

          &:active {
            @include trns(0s);
            background-color: var(--color-gray-5);
          }

          &[disabled] {
            pointer-events: none;
            opacity: 0.3;
          }
        }

        &__select {
          box-shadow: none;
          margin-top: 2px;

          &:last-child {
            margin-left: -2px;
          }

          select {
            height: 30px;
            padding: 0 10px !important;
            width: auto;
            &.duet-date__select--year {
              min-width: 70px;
            }
          }

          span {
            margin-right: 1px;
          }

          svg {
            opacity: .7;
          }

          &-label {
            font-size: 1rem;
            padding-top: 2px;
            height: 30px;
            padding-right: 2px;
          }

          .duet-date__select-label {
            position: relative;
            z-index: 1;

            &:before {
              content: '';
              width: 100%;
              height: 100% !important;
              left: 0;
              top: 0;
              border-radius: var(--ui-roundness);
              position: absolute;
              z-index: -1;
              background-color: var(--color-gray-6);
              opacity: 0;
              @include trns;
            }
          }

          select:focus+.duet-date__select-label {
            box-shadow: none;
            z-index: 2;
          }

          @include mouse {
            select:hover:not(:focus, :focus-visible)+.duet-date__select-label {
              &:before {
                opacity: 1;
              }
            }
          }

          select:focus-visible+.duet-date__select-label {
            outline-style: auto;
          }
        }


        &__dialog {
          min-width: unset;
          justify-content: flex-end;
          @media (prefers-reduced-motion) {
            transition: none;
          }
        }

        &__dialog-content {
          box-shadow: var(--ui-box-shadow, none);
          max-width: unset;
          min-width: 260px;
          margin-top: 4px;
          border: none;
          padding: var(--duet-spacing);

          @media (max-width: 35.9375em) {
            min-height: 30em;
          }
        }
      }

      select {
        --field-height: 23px;
        padding: 0 !important;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    padding-top: .3rem;
    gap: 10px;
  }

  &__edit-thumb,
  &__edit-thumb-loader {
    width: 18px;
    height: 18px;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 3px;
    object-fit: cover;

    .btn-action & {
      margin-right: 8px;
      margin-left: -2px;
    }
  }

  &__edit-thumb-loader {
    background: #f0f0f0;
  }

  &__edit-thumb {
    mix-blend-mode: multiply;
  }
}

:root {
  --duet-color-primary: var(--color-primary-lighter);
  --duet-color-text: var(--color-gray-1);
  --duet-color-text-active: #fff;
  --duet-color-placeholder: #666;
  --duet-color-button: #f5f5f5;
  --duet-color-surface: #fff;
  --duet-color-overlay: var(--ui-backdrop);
  --duet-color-border: var(--color-gray-1);

  --duet-spacing: 16px;
  --duet-font: var(--font);
  --duet-font-normal: 500;
  --duet-font-bold: 500;

  --duet-radius: var(--ui-roundness);
  --duet-z-index: 101;
}

:root {
  --tox-font: var(--font);
  --tox-roundness: var(--ui-roundness);
  --tox-base-color: var(--color-base);
  --tox-primary-color: var(--color-gray-1);
  --tox-hover-color: var(--color-primary);
  --tox-active-color: var(--color-primary-darker);
  --tox-divider-border: 1px solid transparent;
  --tox-editor-border: none;
  --tox-toolbar-button-hover-color: var(--color-gray-6);
  --tox-toolbar-button-active-color: var(--color-gray-5);
  --tox-toolbar-button-enabled-color: var(--color-gray-4-5);
  --tox-dialog-bg: var(--ui-backdrop);
  --tox-divider-color: var(--ui-divider-color);
  --tox-dialog-outer-spacing: calc(2rem - 16px);
  --tox-input-outline: var(--color-gray-4);

  @include xs {
    --tox-dialog-outer-spacing: calc(1.5rem - 16px);
  }
}

.tox-toolbar__group:not(:last-of-type) {
  position: relative;

  &:before {
    content: '';
    width: 1px;
    height: 24px;
    position: absolute;
    top: calc(50% - 12px);
    right: -1px;
    background: var(--ui-divider-color);
  }
}

.tox .tox-tbtn--bespoke[title="Blocks"]:not(:last-child) {
  margin-right: 6px;
}

.tox .tox-tbtn__select-label {
  font-size: 13px !important;
}

@include xxs {
  .field {
    .tox .tox-tbtn.tox-tbtn--select {
      width: 98px !important;
    }

    .tox .tox-toolbar__group {
      padding: 0 10px 0 10px;
    }

    .tox .tox-tbtn:not(.tox-tbtn--select, .tox-split-button__chevron) {
      width: 26px !important;
    }
  }
}