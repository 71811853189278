@use "../abstracts/" as *;

.send-checkout {
  padding-left: var(--column-gap);
  align-self: flex-start;
  border-left: 1px var(--ui-divider-color) solid;
  position: sticky;
  top: calc(var(--main-bar-height) * var(--header-sticky) + 3rem);
  margin-top: 1rem;
  min-width: 282px;
  @include xs {
    align-self: unset;
    border: none;
    position: static;
    padding: 0;

    &.--gift-checkout {
      position: sticky;
      bottom: 0;
      z-index: 10;
      margin-top: .5rem;
      margin-bottom: calc(-1 * var(--offset-gap));
      -webkit-box-shadow: inset 0 1px 0 0 var(--ui-divider-color),0 10px 0 10px var(--color-white);
      box-shadow: inset 0 1px 0 0 var(--ui-divider-color),0 10px 0 10px var(--color-white);
      padding: 1rem 1rem var(--offset-gap);
      background-color: #f6f6f6;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .btn.--submit.--full-width {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.send-button-wrapper {
  position: sticky;
  bottom: 0;
  z-index: 10;
  margin-top: 0.5rem;
  margin-bottom: calc(-1 * var(--offset-gap));
  box-shadow: inset 0 1px 0 0 var(--ui-divider-color),
    0 10px 0 10px var(--color-white);
  padding: 1rem 0 var(--offset-gap);
  background-color: var(--color-white);

  @media (max-height: 500px) {
    position: relative;
  }
}

.send-checkout-button {
  .--without-gift {
    display: flex;
  }
  .--with-gift {
    display: none;
  }

  &.--has-gift {
    .--without-gift {
      display: none;
    }
    .--with-gift {
      display: flex;
    }
  }
}

.confirm-share {
  --ui-vertical-spacing: 1.35rem;

  &__thumb {
    box-shadow: var(--ui-box-shadow);
    position: absolute;
    border-radius: 8px;
    width: 190px;
    top: 25%;
    left: 17%;

    .--email & {
      top: 20%;
    }

    @include sm {
      top: 19%;
    }

    @include xs {
      top: 10%;
      left: 36%;

      .--email & {
        top: 10%;
      }
    }

    @include xxs {
      left: 32%;
    }

    @media only screen and (max-width: 390px) {
      width: 48%;
      left: 32%;
    }
  }

  &__gift-thumb {
    box-shadow: var(--ui-box-shadow);
    position: absolute;
    width: 95px;
    top: 71%;
    left: 30%;

    @include xs {
      top: 38%;
      left: 58%;
    }

    @include xxs {
      top: 36%;
    }

    @media only screen and (max-width: 365px) {
      top: 32%;
    }
  }

  &__background {
    margin: auto;

    svg {
      width: 100%;
    }
  }

  &__send-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;

    @include xs {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__buttons {
    display: flex;
    gap: 15px;
    align-items: center;

    @include xs {
      gap: 12px;
      justify-content: center;
      flex-wrap: wrap;
    }

    &-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }

    a:not(.share-btn) {
      display: block;
      padding: 7px;
      position: relative;
      height: 36px;
      width: 36px;
      border-radius: 50%;

      &:after {
        pointer-events: none;
        content: "";
        @include setInset(0);
        border-radius: 50%;
        background-color: var(--color-gray-2);
        opacity: 0;
        transform: scale(0.3);
        @include trns(0.1s);
      }

      &:hover,
      &:focus {
        &::after {
          cursor: pointer;
          opacity: 0.1;
          transform: scale(1);
        }
      }

      &:focus-visible {
        &::after {
          @include trns(0);
          transform: scale(1);
        }
      }

      &:active {
        &::after {
          @include trns(0);
          opacity: 0.2;
        }
      }
    }
  }
}

.recipients-table {
  --ui-vertical-spacing: 1.6rem;

  .table {
    --table-divider-color: transparent;
    --table-odd-background: transparent;

    &__element {
      thead {
        top: 0;
        position: static;

        th {
          padding: var(--table-cell-block) var(--table-cell-inline);
          color: var(--color-gray-2);
        }
      }

      tbody {
        .table__cell {
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      tr {
        border-bottom: 1px solid var(--color-gray-5);
      }
    }
  }
}

#send-flow .signed-out-message {
  margin-top: calc(var(--spacing) * 1);

  @include sm {
    margin-top: calc(var(--spacing) * 1.55);
  }
}

.send-confirm {
  @include xs {
    --container-gaps: calc(430px + var(--offset-gap) * 2);
  }

  h1 {
    text-wrap: balance;
  }

  &.--share {
    .text-block {
      &>div {
        margin: calc(var(--vertical-spacing) * 1.2 * var(--scale)) 0;

        body:not(.--is-signed-in) & {
          margin-bottom: 0;
        }
      }
    }

    [data-flow-action="copy"] {
      .btn__text {
        min-width: 73px;
      }
    }
  }

  &__reminder {
    margin-top: calc(var(--spacing) * 1.5) !important;

    .flow:not(.--has-birthday-reminder, .--has-anniversary-reminder) & {
      display: none;
    }

    @include to_xs {
      max-width: 650px;
    }
  }

  &__birthday-reminder {
    display: none;

    .flow.--has-birthday-reminder & {
      display: block;
    }

    .flow.--has-birthday-reminder:not(.--has-anniversary-reminder) & {
      .form__event-header {
        display: none;
      }

      .inline.--event-structure {
        padding-top: 0;
      }
    }
  }

  &__anniversary-reminder {
    display: none;

    .flow.--has-anniversary-reminder & {
      display: block;
    }

    .flow.--has-anniversary-reminder:not(.--has-birthday-reminder) & {
      .form__event-header {
        display: none;
      }

      .inline.--event-structure {
        padding-top: 0;
        margin-top: -3px;
      }
    }
  }

  .--legal-info {
    padding: 8px 16px;
    border-radius: var(--ui-roundness,0);
    background-color: rgba(36,109,190,.07);
    color: var(--color-info-text);
  }
}

.confirm-image {
  --product-width: 53.6372%;
  --product-left: 31.4534%;
  --product-top: 17.9177%;
  --gift-width: 28.6046%;
  --gift-left: 52.093%;
  --gift-top: 72.3464%;
  --gift-box-shadow: none;
  --product-box-shadow: 0 11px 11px #0001;
  position: relative;
  max-width: 430px;
  margin: 0 auto;

  &.--creatacard-envelope {
    --product-box-shadow: none;
    --gift-box-shadow: 0 11px 11px #0001;
    --product-width: 83.572093%;
    --product-left: 10.7186%;
    --product-top: 10.8255%;
    --gift-width: 26.279%;
    --gift-left: 16.9767%;
    --gift-top: 75.1458%;
    --product-mask: none;
    &.--bifoldportrait {
      --product-mask: url("#cac-envelope-bifoldportrait-mask");
    }
    &.--doublesidedlandscape {
      --product-mask: url("#cac-envelope-doublesidedlandscape-mask");
      --product-top: 7.8255%;
      --gift-width: 27.279%;
      --gift-left: 51.9767%;
      --gift-top: 76.1458%;
    }
    &.--doublesidedportrait {
      --product-mask: url("#cac-envelope-doublesidedportrait-mask");
    }
  }

  &.--giftcard-first {
    --product-box-shadow: none;
    --product-width: 66.6372%;
    --product-left: 8.4534%;
    --product-top: 10.9177%;
    --gift-box-shadow: 0 11px 11px #0001;
    --gift-width: 28.6046%;
    --gift-left: 70.093%;
    --gift-top: 21.3464%;

    .confirm-image__product {
      background: transparent;

      .lazy-load__loader {
        display: none;
      }
    }
  }

  &.--creatacard {
    --product-width: 45.8186046%;
    --product-left: 20.5465%;
    --product-top: 9.792949%;
    --gift-width: 26.279%;
    --gift-left: 71.395348%;
    --gift-top: 20.252%;
    --gift-box-shadow: 0 11px 11px #0001;

    &.--doublesidedlandscape {
      --product-width: 60.6372%;
      --product-left: 24.4534%;
      --product-top: 17.9177%;
      --gift-width: 28.6046%;
      --gift-left: 52.093%;
      --gift-top: 68.3464%;
      --gift-box-shadow: 0 11px 11px #0001;
    }
  }

  &.--digital-gift {
    --product-width: 80.6372%;
    --product-left: 11.4534%;
    --product-top: 13.9177%;
  }

  &__artwork {
    svg {
      width: 100%;
      height: auto;
    }
  }

  &__product {
    position: absolute;
    left: var(--product-left);
    top: var(--product-top);
    width: var(--product-width);
    border-radius: 10px;
    box-shadow: var(--product-box-shadow);
    background-color: var(--color-white);
    overflow: hidden;

    @include xxs {
      border-radius: 6px;
    }

    .confirm-image.--creatacard-envelope & {
      border-radius: 0px;
      background-color: transparent;
      filter: drop-shadow(2px 4px 8px #0002);
      .lazy-load {
        clip-path: var(--product-mask, none);
        background-color: var(--color-white);
      }
    }
  }

  &__gift {
    &.--custom-song {
      --gift-width: 65px;
      height: 65px;
      --gift-left: 25.9767%;
      --gift-top: 77.1458%;
      --gift-box-shadow: 0 11px 11px #0001;
      background-color: transparent;
      .product-page:not(.--product-model-creatacard) & {
        --gift-left: 62%;
        --gift-top: 69%;
      }
    }
    position: absolute;
    left: var(--gift-left);
    top: var(--gift-top);
    width: var(--gift-width);
    overflow: hidden;
    border-radius: 6px;
    background-color: var(--color-white);
    box-shadow: var(--gift-box-shadow);
    .confirm-image.--creatacard-envelope & {
      .lazy-load {
        margin: 0;
      }
    }

    .flow:not(.--has-gift) & {
      display: none;
    }

    .--amount {
      position: absolute;
      bottom: .25rem;
      right: .5rem;
      color: var(--color-white);
      font-weight: 700;
      font-size: 1.25rem;
    }
  }
}

.jifiti-container {
  --jifiti-iframe-height: 660px;

  .--giftcard-first-container & {
    --jifiti-iframe-height: 725px;
  }

  height: var(--jifiti-iframe-height);

  &__wrapper {
    height: var(--jifiti-iframe-height);
    border-radius: var(--ui-roundness-lg, 10px);
    position: relative;
    &:after {
      content: "";
      @include setInset;
      pointer-events: none;
      border-radius: inherit;
      box-shadow: inset 0 0 0 1px var(--ui-divider-solid-color);
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }
}

.generate-message-sendflow {
  z-index: 3;
  position: relative;

  .tabs__inner {
    padding-bottom: 2rem;
  }

  .generate-message {
    padding-top: 1.75rem;
  }
}